//
//
//
//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    computed: {
        slides () {
            return get(this.section, 'fields.items', [])
        },
        swiperOptions () {
            return {
                autoplay: {
                    delay: 10000
                },
                pagination: {
                    el: `#swiper-pagination-${this._uid}`,
                    clickable: true
                }
            }
        }
    },
    methods: { get }
}
